


import { graphql, useStaticQuery } from 'gatsby'

export const PortflolioData = () => {
  const { wdwordpress: { page: { portfolio_contents } } } = useStaticQuery(graphql`
  {
    wdwordpress {
      page(id: "cGFnZToyNjk=") {
        portfolio_contents {
          banner {
            highlightedText {
              en
              sv
            }
            preTitle {
              en
              sv
            }
            text {
              en
              sv
            }
            title {
              en
              sv
            }
          }
          portfolioBlock {
            fieldGroupName
            portfolioFile {
              en {
                mediaItemUrl
              }
              sv {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
`)
  return portfolio_contents
}