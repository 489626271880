/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable global-require */
import React, { useState, useCallback } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import { Modal } from "react-bootstrap"
import { PortflolioData } from "../../queries/portfolio"
import { useSelector } from "react-redux"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PortfolioBlock = () => {
  const { language } = useSelector(state => state.global)

  const { banner, portfolioBlock } = PortflolioData()
  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(1.0)
  const [fullscreen, toggleFullscreen] = useState(false)

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages)
  }, [])

  const pdfFile = portfolioBlock.portfolioFile[language].mediaItemUrl

  return (
    <section className="career_block client_block portfolio_block">
      <div className="container" style={{ marginTop: 70 }}>
        <div className="row text_side p-0">
          <div className="txt_block">
            <div className="lftbrdr">
              <h6>{banner?.preTitle[language]}</h6>
              <h2>{banner?.title[language]}</h2>
              <p>{banner?.text[language]}</p>
            </div>
          </div>
        </div>
        <div className="portfolio whiteset">
          <div className="col-md-10 mx-auto d-flex justify-content-center">
            <Document
              file={pdfFile}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                className="card d-flex justify-content-center border-white"
                pageNumber={pageNumber}
                width={1000}
                scale={scale}
              />
            </Document>
          </div>
          <div className="row" style={{ zIndex: 19999 }}>
            <div className="pdf-btn-group d-flex justify-content-spacebetween">
              <div
                className="w-100 justify-content-between d-flex align-items-center"
                role="group"
                aria-label="Basic example"
              >
                <div>
                  <a
                    href={pdfFile}
                    style={{ color: "red !important" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="cu-pe fas  fa-arrows-alt m-2" />
                  </a>
                </div>
                <div className="d-flex align-items-center">
                  <i
                    className="cu-pe fa fa-chevron-left"
                    aria-hidden="true"
                    onClick={() =>
                      setPageNumber(currentPage => Math.max(1, currentPage - 1))
                    }
                  />
                  <h6
                    style={{ color: "#fff" }}
                    className="m-2"
                    disabled
                  >{`${pageNumber} of ${numPages}`}</h6>
                  <i
                    className="cu-pe fa fa-chevron-right"
                    aria-hidden="true"
                    onClick={(e) => {
                      e.stopPropagation()
                      setPageNumber(currentPage =>
                        Math.min(numPages, currentPage + 1)
                      )
                    }
                    }
                  />
                </div>

                <div>
                  <a
                    href={pdfFile}
                    className="m-2"
                    target="_blank"
                    rel="noopener noreferrer"
                    download="Webdura_Tech_Portfolio.pdf"
                  >
                    <i className="cu-pe fas fa-download" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={fullscreen}
            centered
            onHide={() => toggleFullscreen(false)}
          >
            <div>
              <Document
                file={pdfFile}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  className="mx-auto w-100 d-flex justify-content-center"
                  pageNumber={pageNumber}
                  width={1000}
                  scale={scale}
                />
              </Document>
            </div>
            <div
              className="btn-group p-2"
              role="group"
              aria-label="Basic example"
            >
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() =>
                  setPageNumber(currentPage => Math.max(1, currentPage - 1))
                }
              >
                <i className="fa fa-chevron-left" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                disabled
              >{`${pageNumber} of ${numPages}`}</button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() =>
                  setPageNumber(currentPage =>
                    Math.min(numPages, currentPage + 1)
                  )
                }
              >
                <i className="fa fa-chevron-right" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setScale(prevScale => prevScale + 0.5)}
              >
                <i className="fa fa-plus" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() =>
                  setScale(prevScale => Math.max(1.0, prevScale - 0.5))
                }
              >
                <i className="fa fa-minus" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => toggleFullscreen(true)}
              >
                <i className="fa fa-arrows-alt" aria-hidden="true" />
              </button>
              <a
                href={pdfFile}
                className="btn btn-secondary"
                target="_blank"
                rel="noopener noreferrer"
                download="Webdura_Tech_Portfolio.pdf"
              >
                <i className="fas fa-download" />
              </a>
            </div>
          </Modal>
        </div>
      </div>
    </section>
  )
}

export default PortfolioBlock
